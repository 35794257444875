import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import { LocalBusinessJsonLd } from 'gatsby-plugin-next-seo';

import Header from "../components/header"
import Story from "../components/story"
import Uryslygi from "../components/uryslygi"
import Sdelka  from "../components/sdelka"
import Price from "../components/price"
import Catalog from "../components/catalog"

import OtzivAudio from "../components/otzivAudio"
import OtzivVideo from "../components/otzivVideo"
import OtzivSocial from "../components/otzivSocial"
import OtzivBlank from "../components/otzivBlank"
import OtzivMap from "../components/otzivMap"
import Social from "../components/social"
import Certificate from "../components/certificate"
import Users from "../components/users"

import Blog from "../components/blog"
import Text from "../components/text"
import Map from "../components/map"

import ContactForm from "../components/contactForm"
import Call from "../components/call"
import Sale from "../components/sale"
import CallForm from "../components/callForm"
import Stado from "../components/stado"
import Policy from "../components/policy"

export const query = graphql `
	query  {
		blogs: allBlog(sort: { order: DESC, fields: datePublished }, limit: 10) {
			edges {
			  node {
				slug
				title
				description
				card
				img
				imgMini
				date
				seoTitle
				seoDescription
				author
				ava
				time
			  }
			} 
		}
	}
`

const IndexPage = ({data}) => {
	return (
		<Layout>
			<Seo title="Оформление недвижимости в Казани | Юридическая компания | Алмаз" description="! ⚖️ «Алмаз» ➥ ⭐ На сегодняшний день сфера оказания юридических услуг очень распространенная отрасль, которая осуществляется большинством юридических компаний, способных помочь в различных отраслях юриспруденции, связанных с оформлением недвижимости в Казани, наследством, правовыми документами и многим другим." />

			<LocalBusinessJsonLd
				type='Store'
				id='https://юридические-услуги-казань.рф/oformlenie-nedvizhimosti-v-kazani'
				name="Оформление недвижимости в Казани | Юридическая компания | Алмаз"
				description="! ⚖️ «Алмаз» ➥ ⭐ На сегодняшний день сфера оказания юридических услуг очень распространенная отрасль, которая осуществляется большинством юридических компаний, способных помочь в различных отраслях юриспруденции, связанных с оформлением недвижимости в Казани, наследством, правовыми документами и многим другим."
				url='https://юридические-услуги-казань.рф'
				telephone='+78005519518'
				address={{
					streetAddress: 'Щапова, 26',
					addressLocality: 'Казань',
					addressRegion: 'Татарстан',
					postalCode: '420012',
					addressCountry: 'RU',
				}}
				geo={{
					latitude: '55.791461',
					longitude: '49.136606',
				}}
				images={[
					'https://юридические-услуги-казань.рф/img/logo.png'
				]}
			/>

			<Header title="Оформление недвижимости в Казани" card="Сомневаетесь, что хороший юрист Вам по карману? Не знаете, как подготовиться к судебному процессу и защитить свои права? Запутались в законах и не знаете, как грамотно сформулировать претензию ?" />
			<Story />
			<Uryslygi />
			<Sdelka />
			<Price />
			<Catalog />
			<OtzivAudio />
			<OtzivVideo />
			<OtzivSocial />
			<OtzivBlank />
			<OtzivMap />
			
			<Social />
			<Certificate />
			<Users />

			<Blog data={data.blogs} />

			<Text 
				title={"Оформление недвижимости в Казани"}
			
				html={

				`

				<p class="TextText">
				В нашей жизни все изменчиво, и иногда встречаются такие случаи, когда бывает необходима помощь профессионалов – юристов. На сегодняшний день сфера оказания юридических услуг очень распространенная отрасль, которая осуществляется большинством юридических компаний, способных помочь в различных отраслях юриспруденции, связанных с оформлением недвижимости в Казани, наследством, правовыми документами и многим другим.
				</p>

				<p class="TextText">
				Чаще всего под юридической услугой понимается тщательная консультация, а также помощь во множестве других вопросов, связанных со сбором документов, анализом успешного окончания вашего дела в судебных инстанциях, помощью в правильном проведении проверок и т.д.
				</p>

				<p class="TextText">
				Регистрация права собственности производится физическими лицами и юридическими лицами, различными федеральными структурами и субъектами РФ, а также муниципальными образованиями.
				</p>

				<p class="TextText">
				Самой распространенной отраслью юридической сферы являются сделки с недвижимостью, так как это очень продолжительная и сложная операция, которую очень сложно правильно решить без консультации профессионалов. Конечно же, имеется вариант обращения в риэлторскую компанию, но их главная задача заключается в поиске необходимого продавца либо покупателя, сопровождение сделок с недвижимостью с помощью юристов заключается в гарантии безопасного ведения дела, без последующих рисков и проблем. И она заключается в следующем:
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					правильное составление документов
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					консультации клиента по всем вопросам, возникающим на каждом этапе дела
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					осуществление переговоров
				</p>

				<p class="TextText">
				Для того чтобы осуществить правильное оформление недвижимости в Казани, сначала необходимы сбор и составление договоров должным образом, чего без знания данной отрасли науки невозможно. Поэтому специалисты нашей компании берут на себя ответственность за такой сложный процесс, как правильное составление правовых документов. Кроме всего вышеперечисленного они подадут, а затем и заберут все уже готовые документы из необходимых органов и передадут их Вам. Только после оформления всех документов процесс оформления недвижимости в Казани будет считаться законченным, а Вы получите свидетельство о государственной регистрации.
				</p>

				<p class="TextText">
				Все сделки, связанные с недвижимостью, на сегодняшний день являются очень популярными, так как это сфера бизнеса является наиболее перспективной и стремительно развивающейся, это связано с постоянным строительством новых жилых домов, большим спросом на осуществление сделок купли-продажи квартир. Также стоит отметить, что сделки с недвижимостью являются самыми дорогостоящими. Поэтому юридические компании пользуются большим спросом. Они могут проводить абонентское обслуживание организаций, таким образом ведение любого дела не окажется трудным.
				</p>

				<p class="TextText">
					<b>Тонкости в оформлении недвижимости</b>
				</p>

				<p class="TextText">
				Особое внимание необходимо обратить на признание права собственности на квартиры в новых домах, так как здесь очень часто возникает много проблем, часто документы бывают уже собранными, все деньги переданы, а право управлять данным помещением Вы еще не имеете. В данном случае регистрацию права собственности можно провести через суд. Но и здесь может возникнуть много проблем, связанных с правильным созданием документов, в грамотном ведении дела.
				</p>

				<p class="TextText">
				Встречаются такие случаи, когда право на какую-либо недвижимость регистрируется из-за некоторых факторов неправильно и имеет юридическую уязвимость. В таких случаях недвижимость остается в некоем непонятном состоянии, что может привести к нежелательным последствиям, которые не позволят нормально управлять данной недвижимостью, что приводит к опасению и переживаниям людей, которым принадлежит эта недвижимость.
				</p>

				<p class="TextText">
				При данных обстоятельствах без опасений можете положиться на нашу организацию, мы окажем только квалифицированную помощь, быстро и надежно, и поможем осуществить все по закону. Мы поможем в оформлении недвижимости в Казани только на Вас или передать ее кому-то.

				Предоставляемые нами юридические услуги включают в себя:
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					переоформление недвижимости на другое лицо, например, родственнику. Это бывает необходимо при следующих обстоятельствах
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					возможность обращения собственника квартиры по взысканию по долгам
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					не очень хорошие отношения с тем, кому по праву наследуется квартира
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					смена семейного положения
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					дарение либо продажа квартиры третьему лицу
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					квартира передается, чтобы оплатить долги
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					недвижимость по наследству либо по завещанию
				</p>


				`

			}/>

			<Map />

			<ContactForm />
			<Sale />
			<Call />
			<CallForm />
			<Stado />
			<Policy />
		</Layout>
	)
}


export default IndexPage
